<template>
   <div class="container h-100">

    

      <div class="h-75 pt-5">
        <div class="d-flex justify-content-start align-items-center">
            <p style="cursor:pointer; font-size:22px;"><i class='mdi mdi-exit-to-app' v-on:click="$root.child_logout()"> Exit</i></p>
        </div>

        <div class="d-flex justify-content-center align-items-center h-100 flex-column">

         <div class="d-flex">

            <div class="d-flex me-5" v-if="this.rldata.screen_flags['remember_info_screen']">
               <div class="card-body">
                  <div>
                        <p class="m-0 p-0 mb-4" style="font-weight: 500; font-size: 22px; text-align:center;">Please, write down your account details</p>

                        <div style="background: #FFFFFF; border-radius: 12px; padding: 16px;">
                            <div class="d-flex justify-content-between">
                                <p style="font-size: 14px;">My username</p><p><b>{{rldata.child_data.username}}</b></p>
                            </div>
                            <div class="d-flex justify-content-between mb-3">
                                <p style="font-size: 14px;">My password </p><p><b>{{rldata.child_data.raw_password}}</b></p>
                            </div>

                            <div style="background: #FDF4D6; border-radius: 8px; padding: 8px 16px;">
                                <p class="m-0 p-0"><i class="mdi mdi-alert" style="color: coral;"></i> This is your automatically generated account details.<br>It is important to save this information for login purposes.<br>Please, write it down or copy securely. </p>
                            </div>
                        </div> 

                        <button class="btn mt-4" style="background: #55D18E; box-shadow: 0px 4px 0px #4FBC81; border-radius: 12px; color: white; width: 100%;" @click="login">Next</button>

                        <!-- <form v-on:submit="login">
                            <div class="form-group mb-3">
                                <label for="inputUsername">Username</label>
                                <input type="text" class="form-control" id="inputUsername" readonly v-model="rldata.child_data.username">
                            </div>

                            <div class="form-group mb-3">
                                <label for="inputPassword">Password</label>
                                <input type="text" class="form-control" id="inputPassword" readonly v-model="rldata.child_data.raw_password">
                            </div>

                            <button type="submit" class="btn" style="border: 1px solid grey; text-align:center; padding: 10px; width:100%;">Next</button>
                        </form> -->
                  </div>
               </div>
            </div>

         </div>
        </div>

      </div>
   </div>
</template>

<script>
fbq('track', 'Lead');

// import axios from "axios"
// import router from "@/router"
var rldata = {
    child_data: null,

    screen_flags: {
        remember_info_screen: false,
    },
}
export default {
  name: "Child_Setup",
  data () {
    return {
      rldata
    }
  },
  methods: {
    login: function(e) {
      e.preventDefault();
      this.$root.childLogin(this.rldata.child_data.username, this.rldata.child_data.raw_password);
    }
  },
  mounted () {
      try{

        //get child from ls or exit
        this.$root.getChildDataLS(this.rldata);

      } catch(err){
        //get child from ls or exit
        this.$root.child_logout();
      }
  },
    beforeMount() {
      var cleanData = {
        child_data: {
            username: '',
            password: '',
            raw_password: '',
        },

        screen_flags: {
            remember_info_screen: false,
        },
    };
      this.rldata = cleanData;
   },
   watch: {
        "rldata.child_data": function(handler) {
            if(handler != null){
                this.rldata.screen_flags.remember_info_screen = true;
                localStorage.removeItem('child_setup');
            } 
        }
   }
}
</script>